import moment from "moment";

export const TimePassedPipe = (date: Date): string => {
  if (!date) {return '';}
  const _date = moment(date.toISOString());
  const now = moment();

  const diffInSeconds = now.diff(_date, 'seconds');
  const diffInMinutes = now.diff(_date, 'minutes');
  const diffInHours = now.diff(_date, 'hours');

  const diffInDays = now.diff(_date, 'days');
  const diffInWeeks = now.diff(_date, 'weeks');
  const diffInMonths = now.diff(_date, 'months');
  const diffInYears = now.diff(_date, 'years');

  switch (true) {
    case (diffInMonths > 14): return diffInMonths % 12 > 0 ? `${diffInYears} years, ${diffInMonths % 12} months ago` : `${diffInYears} years ago`;
    case (diffInMonths > 2): return `${diffInMonths} months ago`;
    case (diffInWeeks > 2): return `${diffInWeeks} weeks ago`;
    case (diffInDays > 1): return `${diffInDays} days ago`;
    case (diffInHours > 17): return `yesterday`;
    case (diffInHours > 6): return `today`;
    case (diffInHours >= 1): return `${diffInHours} ${diffInHours > 1 ? 'hours' : 'hour'} ago`;
    case (diffInMinutes >= 1): return `${diffInMinutes} min ago`;
    case (diffInSeconds >= 0): return `${diffInSeconds} sec ago`;
  }

  return '';
}
